import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';

const MeetingTimes = () => {
  const [meetingTimes, setMeetingTimes] = useState([]);
  const [open, setOpen] = useState(false);
  const [newMeeting, setNewMeeting] = useState({ title: '', start: new Date(), end: new Date(), room: 'room1' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMeetingTimes = async () => {
      try {
        const response = await axios.get('https://backendforerpsystem.mohrek.com/fetch_meeting_times.php?room=room1');
        setMeetingTimes(response.data);
      } catch (error) {
        console.error('Error fetching meeting times:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMeetingTimes();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddMeeting = async () => {
    try {
      const response = await axios.post('https://backendforerpsystem.mohrek.com/add_meeting_time.php', {
        title: newMeeting.title,
        start: moment(newMeeting.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(newMeeting.end).format('YYYY-MM-DD HH:mm:ss'),
        room: newMeeting.room
      });
      if (response.data.success) {
        setMeetingTimes([...meetingTimes, { ...newMeeting, id: response.data.id }]);
        Swal.fire({
          icon: 'success',
          title: 'تمت الإضافة بنجاح',
          text: 'تمت إضافة الاجتماع الجديد بنجاح',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: response.data.message,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: 'حدث خطأ أثناء إضافة الاجتماع',
      });
      console.error('Error adding meeting:', error);
    }
    setOpen(false);
  };

  const handleDeleteMeeting = async (id) => {
    Swal.fire({
      title: 'هل أنت متأكد؟',
      text: 'لن تتمكن من التراجع عن هذا!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'نعم، احذفها!',
      cancelButtonText: 'إلغاء'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post('https://backendforerpsystem.mohrek.com/delete_meeting_time.php', { id });
          if (response.data.success) {
            setMeetingTimes(meetingTimes.filter(meeting => meeting.id !== id));
            Swal.fire({
              icon: 'success',
              title: 'تم الحذف بنجاح',
              text: 'تم حذف الاجتماع بنجاح',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: response.data.message,
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: 'حدث خطأ أثناء حذف الاجتماع',
          });
          console.error('Error deleting meeting:', error);
        }
      }
    });
  };

  return (
    <Box p={2} style={{ fontFamily: 'Cairo, sans-serif', direction: 'rtl', backgroundColor: '#1f2224', color: 'white' }}>
      <Typography variant="h5" gutterBottom style={{ color: '#6fd943' }}>أوقات الاجتماعات (الغرفة 1)</Typography>
      <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginBottom: '16px', backgroundColor: '#6fd943', color: '#fff' }}>
        إضافة اجتماع جديد
      </Button>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress style={{ color: '#6fd943' }} />
        </Box>
      ) : (
        <Paper style={{ marginBottom: '16px', padding: '16px', backgroundColor: '#141718', color: 'white' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>العنوان</TableCell>
                  <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>تاريخ البدء</TableCell>
                  <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>تاريخ الانتهاء</TableCell>
                  <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>الإجراءات</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meetingTimes.map((meeting, idx) => (
                  <TableRow key={idx} style={{ borderBottom: '1px solid #6fd943' }}>
                    <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif', color: 'white' }}>{meeting.title}</TableCell>
                    <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif', color: 'white' }}>{moment(meeting.start).format('LLL')}</TableCell>
                    <TableCell align="right" style={{ fontFamily: 'Cairo, sans-serif', color: 'white' }}>{moment(meeting.end).format('LLL')}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteMeeting(meeting.id)}
                        style={{ backgroundColor: '#d32f2f', color: '#fff' }}
                      >
                        حذف
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { backgroundColor: '#1f2224', color: 'white' } }}>
        <DialogTitle style={{ fontFamily: 'Cairo, sans-serif', color: '#6fd943' }}>إضافة اجتماع جديد (الغرفة 1)</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="العنوان"
            type="text"
            fullWidth
            value={newMeeting.title}
            onChange={(e) => setNewMeeting({ ...newMeeting, title: e.target.value })}
            InputLabelProps={{ style: { color: 'white' } }}
            InputProps={{ style: { color: 'white' } }}
            style={{ fontFamily: 'Cairo, sans-serif' }}
          />
          <TextField
            margin="dense"
            label="تاريخ البدء"
            type="datetime-local"
            fullWidth
            value={moment(newMeeting.start).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => setNewMeeting({ ...newMeeting, start: new Date(e.target.value) })}
            InputLabelProps={{ style: { color: 'white' } }}
            InputProps={{ style: { color: 'white' } }}
            style={{ fontFamily: 'Cairo, sans-serif' }}
          />
          <TextField
            margin="dense"
            label="تاريخ الانتهاء"
            type="datetime-local"
            fullWidth
            value={moment(newMeeting.end).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => setNewMeeting({ ...newMeeting, end: new Date(e.target.value) })}
            InputLabelProps={{ style: { color: 'white' } }}
            InputProps={{ style: { color: 'white' } }}
            style={{ fontFamily: 'Cairo, sans-serif' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: 'white' }}>إلغاء</Button>
          <Button onClick={handleAddMeeting} style={{ backgroundColor: '#6fd943', color: '#fff' }}>إضافة</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MeetingTimes;
