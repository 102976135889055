import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  Typography, Box, TextField, Button, Grid, MenuItem, Select, FormControl, InputLabel, OutlinedInput, Chip, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete'; // Import delete icon
import DatePicker from 'react-datepicker'; // Import DatePicker from react-datepicker
import 'react-datepicker/dist/react-datepicker.css'; // Import react-datepicker styles

const TaskList = () => {
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskDueDate, setTaskDueDate] = useState(null); // Set initial state to null for DatePicker
  const [taskDepartments, setTaskDepartments] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [userTasks, setUserTasks] = useState([]); // New state to hold tasks filtered by user
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const location = useLocation();
  const username = new URLSearchParams(location.search).get('username');

  useEffect(() => {
    // Fetch departments
    axios.get('https://backendforerpsystem.mohrek.com/getDepartments.php')
      .then((response) => {
        if (response.data.success) {
          setDepartments(response.data.departments);
        }
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });

    // Fetch employees and set the current user
    axios.get('https://backendforerpsystem.mohrek.com/getemployees.php')
      .then((response) => {
        if (response.data.status === 'success') {
          const foundUser = response.data.data.find((emp) => emp.username === username);
          setUser(foundUser);
        }
      })
      .catch((error) => {
        console.error('Error fetching employees:', error);
      });

    // Fetch all tasks and filter by current user
    axios.get('https://backendforerpsystem.mohrek.com/get_tasks.php')
      .then((response) => {
        if (response.data.success) {
          const allTasks = response.data.tasks;
          // Filter tasks by current user's username
          const filteredTasks = allTasks.filter(task => task.username === username);
          setTasks(allTasks); // Set all tasks to state (optional, if needed elsewhere)
          setUserTasks(filteredTasks); // Set filtered tasks for rendering
        }
      })
      .catch((error) => {
        console.error('Error fetching tasks:', error);
      });
  }, [username]);

  // Handle adding a new task
  const handleAddTask = () => {
    if (!taskTitle || !taskDescription || !taskDueDate || taskDepartments.length === 0) {
      Swal.fire('خطأ', 'يرجى ملء جميع الحقول', 'error');
      return;
    }
  
    // Convert the taskDueDate to UTC format before sending to the server
    const utcDueDate = new Date(taskDueDate).toISOString().split('T')[0];
  
    const newTask = {
      taskTitle,
      taskDescription,
      taskDueDate: utcDueDate, // Use the UTC formatted date here
      taskDepartments,
      username,
    };
  
    setLoading(true);
    axios.post('https://backendforerpsystem.mohrek.com/add_task.php', newTask)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          Swal.fire('نجاح', 'تم إضافة المهمة بنجاح!', 'success');
          const addedTask = { ...newTask, id: response.data.taskId }; // Include task ID returned from backend
          setTasks([...tasks, addedTask]); // Update tasks state
          setUserTasks([...userTasks, addedTask]); // Update filtered tasks state
          setTaskTitle('');
          setTaskDescription('');
          setTaskDueDate(null); // Reset date after adding
          setTaskDepartments([]);
        } else {
          Swal.fire('خطأ', response.data.message, 'error');
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire('خطأ', 'حدث خطأ أثناء إضافة المهمة. حاول مرة أخرى.', 'error');
      });
  };

  // Handle deleting a specific task
  const handleDeleteTask = (taskId) => {
    Swal.fire({
      title: 'هل أنت متأكد؟',
      text: 'لن تتمكن من التراجع عن هذا!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'نعم، احذفها!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('https://backendforerpsystem.mohrek.com/delete_task.php', { taskId })
          .then((response) => {
            if (response.data.success) {
              setUserTasks(userTasks.filter((task) => task.id !== taskId)); // Remove the task from the filtered state
              Swal.fire('تم الحذف!', 'تم حذف المهمة بنجاح.', 'success');
            } else {
              Swal.fire('خطأ', response.data.message, 'error');
            }
          })
          .catch((error) => {
            Swal.fire('خطأ', 'حدث خطأ أثناء حذف المهمة. حاول مرة أخرى.', 'error');
          });
      }
    });
  };

  const handleDeleteDepartment = (departmentToDelete) => {
    setTaskDepartments((prevDepartments) => prevDepartments.filter((department) => department !== departmentToDelete));
  };

  if (!user) {
    return <CircularProgress />;
  }

  // Check if the user is not a Team Leader and display a message
  if (user.role !== 'Team Leader') {
    return (
      <Box p={3} sx={{ fontFamily: 'Cairo, sans-serif', direction: 'rtl' }}>
        <Typography style={{ fontFamily: 'Cairo' }} variant="h6" color="error">
          لا يحق لك الدخول الى هذه الصفحة
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={3} sx={{ fontFamily: 'Cairo, sans-serif', direction: 'rtl' }}>
      <Typography style={{ fontFamily: 'Cairo' }} variant="h4" gutterBottom>
        قائمة المهام
      </Typography>
      <Typography style={{ fontFamily: 'Cairo' }} variant="body1">
        هذه هي صفحة قائمة المهام. يمكنك عرض جميع مهامك هنا.
      </Typography>
      <Box mt={4}>
        <Typography style={{ fontFamily: 'Cairo' }} variant="h6" gutterBottom>
          إضافة مهمة جديدة
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="عنوان المهمة"
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
              variant="outlined"
              inputProps={{ style: { fontFamily: 'Cairo' } }}
              InputLabelProps={{ style: { fontFamily: 'Cairo' } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              selected={taskDueDate}
              onChange={(date) => setTaskDueDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="اختر تاريخ الاستحقاق"
              customInput={(
                <TextField
                  fullWidth
                  label="تاريخ الاستحقاق"
                  variant="outlined"
                  inputProps={{ style: { fontFamily: 'Cairo' } }}
                  InputLabelProps={{ style: { fontFamily: 'Cairo' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="وصف المهمة"
              value={taskDescription}
              onChange={(e) => setTaskDescription(e.target.value)}
              multiline
              rows={4}
              variant="outlined"
              inputProps={{ style: { fontFamily: 'Cairo' } }}
              InputLabelProps={{ style: { fontFamily: 'Cairo' } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel style={{ fontFamily: 'Cairo' }}>الأقسام المعنية</InputLabel>
              <Select
                multiple
                value={taskDepartments}
                onChange={(e) => setTaskDepartments(e.target.value)}
                input={<OutlinedInput label="الأقسام المعنية" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{ fontFamily: 'Cairo' }}
                        deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                        onDelete={() => handleDeleteDepartment(value)}
                      />
                    ))}
                  </Box>
                )}
                sx={{ fontFamily: 'Cairo' }}
              >
                {departments.map((department) => (
                  <MenuItem key={department.id} value={department.name} sx={{ fontFamily: 'Cairo' }}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddTask}
            sx={{ fontFamily: 'Cairo' }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'إضافة مهمة'}
          </Button>
        </Box>
      </Box>
      <Box mt={4}>
        <Typography style={{ fontFamily: 'Cairo' }} variant="h6" gutterBottom>
          قائمة المهام المضافة
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="task table">
            <TableHead>
              <TableRow>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>عنوان المهمة</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>وصف المهمة</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>تاريخ الاستحقاق</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>الأقسام المعنية</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>اسم المستخدم</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>إجراء</TableCell> {/* New Action Column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {userTasks.map((task, index) => (
                <TableRow key={index}>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.task_title}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.task_description}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.created_at}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.task_departments}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo' }}>{task.username}</TableCell>
                  <TableCell align="right">
                    <IconButton color="error" onClick={() => handleDeleteTask(task.id)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TaskList;
