import React from 'react';
import './Puplichome.css';
import image from "../../images/1212.png";
import image1 from "../../images/1.png";
import imageahmed from "../../images/ahmed.png";
import imagebe from "../../images/BE.png";
import imagegm from "../../images/gm.png";
import imagekhaled from "../../images/khaled.png";
import imageon from "../../images/ON.png";
import imagepro from "../../images/PRO.png";
import imagescoial from "../../images/sco.png";
import NavigationWidget from "./botton";

const Domain = () => {
  return (
      <div className="background-container">
        <img src={image} alt="image" />
        {/* <--! image1 --> */}
        <div className='image'>
      <img src={image1} alt="image1" />
      </div>
        {/* <--! imagesco --> */}
        <div className='sco'>
      <img src={imagescoial} alt="imagescoial" />
      </div>
        {/* <--! imagesgm --> */}
        <div className='gm'>
      <img src={imagegm} alt="imagegm" />
      </div>
        {/* <--! imageahmed --> */}
        <div className='ahmed'>
      <img src={imageahmed} alt="imageahmed" />
      </div>
        {/* <--! imagebe --> */}
        <div className='imagebe'>
      <img src={imagebe} alt="imagebe" />
      </div>
        {/* <--! imagebe2 --> */}
        <div className='imagebe2'>
      <img src={imagebe} alt="imagebe" />
      </div>
        {/* <--! imagekhaled --> */}
        <div className='imagekhaled'>
      <img src={imagekhaled} alt="imagekhaled" />
      </div>
        {/* <--! imagekhaled2 --> */}
        <div className='imagekhaled2'>
      <img src={imagekhaled} alt="imagekhaled" />
      </div>
        {/* <--! imageon --> */}
        <div className='imageon'>
      <img src={imageon} alt="imageon" />
      </div>
        {/* <--! imagepro --> */}
        <div className='imagepro'>
      <img src={imagepro} alt="imagepro" />
      </div>
        {/* <--! text --> */}
        <div className='text'>
      <h2 style={{fontFamily:"cairo"}} className='text1'>
      محرك هي شركة السيو الأفضل في تهيئة وتحسين محركات البحث في الوطن العربي، وأيضا إطلاق
      <br/>
    وإدارة حملات جوجل وحملات السوشيال ميديا بالاضافة لتطوير المواقع لمساعدة موقعك في تحقيق
    <br/>
     المزيد من التقدم والوصول لأضعاف الزوار والحفاظ على النتيجة الأولى في كافة محركات البحث
        </h2>
      </div>
       {/* <--! text2 --> */}
      <div className='text2'>
        <p className='text3'>
        We Are Celebrating Several Years SinceThe
        <br/>
     Establishment Of Our Company As Its Main Principle.
     <br/>
      Since Its Success Years Ago, It Has Achieved Great
      <br/>
        Achievements In Many Fields
        </p>
      </div>
      <NavigationWidget />
      </div>
  
        );
}

export default Domain;
