import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Puplichome.css';

const NavigationWidget = () => {
  const navigate = useNavigate(); // useNavigate hook from react-router-dom

  // Function to handle button click to navigate to login
  const handleNavigate = () => {
    navigate('/login');
  };

  return (
    <div className="widget-container">
      <button className="navigation-widget-button" onClick={handleNavigate}>
         دخول  
      </button>
    </div>
  );
};

export default NavigationWidget;